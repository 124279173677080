// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  {
    path: '/configuration/events',
    name: 'events-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/identificador/Events.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'events',
    },
  },
  {
    path: '/configuration/event-update',
    name: 'events-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/identificador/form/EventUpdate.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'events',
    },
  },
  {
    path: '/configuration/event/citas/:slug',
    name: 'events-citas',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/identificador/form/EventCitas.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'events',
    },
  },
  {
    path: '/configuration/event-socios',
    name: 'events-socios',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/configuration/identificador/Socios.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'events',
    },
  },
]

export default formTable
