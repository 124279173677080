import mutations from './mutations'
import actions from './actions'

const state = {
  shallContentShowOverlay: false,

  // app_name: process.env.VUE_APP_NAME,
  api_v1_1: process.env.VUE_APP_API_URL_1_1,
  currency_code: 'USD',
  currency_symbol: '$',
  app_name: 'Wamasol® Tech™',
  languages: [],
  configDataGeneral: {
    color: '',
    favicon: '',
    isotipo: '',
    logo: '',
    name: '',
  },
  permisosAuth: [],
  cardsDashboard: [],
  chatBotRead: null,
  salesWeeks: [],
  salesWeek: {
    name: null,
  },
  salesMonthAll: [],
  salesMonth: {
    amountAll: 0,
  },
  salesMonthPreviews: {
    name: null,
  },
  salesMonthPreview: {
    name: null,
  },
  contratesPendientsHotelsAll: [],
  contratesPendientsHotelsUser: {
    product: null,
  },
  appsDownload: [],
  dnsEmpty: false,
  productsList: [],
  configCotizadorHotel: {
    days_calendar_in: null,
    days_calendar_out: null,
    pax_adult: null,
    pax_child: null,
    age_child: null,
    age_infant: null,
    max_paxs_all: null,
    max_rooms: null,
    max_paxs_room: null,
    max_adult_room: null,
    max_child_room: null,
    min_adult_room: null,
    min_child_room: null,
  },
  maxAgeCotizadorHotel: null,
  listUser: [],
  showCardCreateUser: false,
  showCardCreateSeller: false,
  showCardCreateAgent: false,
  showCardCreateUserAdmin: false,
  showCardMarkups: false,
  showCardIdentificadorInfo: false,
  showCardIdentificadorBanco: false,
  showCardIdentificadorBooking: false,
  showCardIdentificadorIden: false,
  carsProductCache: [],
  carsProductFiltersCache: {
    totalNotPublic: 0,
    totalInfoPendiente: 0,
    totalSinMarca: 0,
    totalSinModelo: 0,
    totalSinTransmision: 0,
    totalSinCarroseria: 0,
    totalSinCombustible: 0,
    totalSinCantPuertas: 0,
    totalSinCantPaxs: 0,
    totalSinBagSmall: 0,
    totalSinBagBig: 0,
    totalSinUm: 0,
    totalSinCapacidadComb: 0,
    totalSinConsumoComb: 0,
    totalSinMotor: 0,
    totalSinCaracteristicas: 0,
    totalSinPrioridad: 0,
    totalSinFotos: 0,
    totalSinTagFotos: 0,
    totalSinTagPrincipal: 0,
  },
  hotelsProductCache: [],
  hotelsProductListCache: [],
  hotelsProductFiltersCache: {
    totalNotPublic: 0,
    totalInfoPendiente: 0,
    totalSinOperador: 0,
    totalSinMarca: 0,
    totalSinFotos: 0,
    totalSinTagFotos: 0,
    totalSinTagPrincipal: 0,
    totalSinDireccion: 0,
    totalSinLocalidad: 0,
    totalSinCrop: 0,
    totalSinContact: 0,
  },
  carsContrateCache: [],
  hotelsContrateCache: [],
  hotelsContrateListCache: [],
  hotelsContrateShowCache: {
    id: null,
    source: [],
  },
  activeDeepL: false,
  deepLCountTranslate: {
    countAll: null,
    countPt: null,
    countEn: null,
    countDe: null,
    countRu: null,
    countFr: null,
  },
  afiliadoPendient: null,

  emailsCodes: [],
  emailsReserve: [],
  phonesReserve: [],
  emailsSoporte: [],
  phonesSoporte: [],
  ctasBancarias: [],
  proveedorApis: [],
  clientsApis: [],
  user: {
    permisos: [],
  },
  viewPermisos: null,
  isLoadingPagePermisos: true,
  sourcePermisos: [],
  sourceTableros: [],
  updateBooking: false,
  showBooking: true,
  showProduct: true,
  showContrate: true,
  showAdministration: true,
  showAPIs: true,
  showAPPs: true,
  showConfiguration: true,
  showNomencladores: true,
  showNomencladoresFlights: true,
  showNomencladoresCars: false,
  showNomencladoresHotels: false,

  userProfile: {},
  isLoadingPage: true,
  perfilesAll: [],
  perfilesAuth: [],
  permisosItem: [],
  cargandoMenu: true,

  loadEscalas: false,
  escalas: [],

  loadFrecuencias: false,
  frecuencias: [],

  loadClasesVuelos: false,
  loadTarifasVuelos: false,
  clasesVuelos: [],
  umPesoBodega: '',
  umDimensionBodega: '',

  // USERS
  searchUsers: {
    name: null,
    company: 0,
    rol: 0,
    active: 2,
  },
  userUpdate: {
    id: null,
    name: null,
    apellidos: null,
    email: null,
    role_id: null,
    active: 1,
  },
  userOnlyShow: false,
  userUpdateAvatar: null,
  userItemsPerPage: 10,
  totalUsersFilters: 0,
  totalUsersAll: 0,

  // MARKUPS
  temporadasFlightMarkups: [],
  markupsFlightMarkups: [],

  // GEOTAG
  geotag: {
    longitud: null,
    latitud: null,
  },

  textEnriquecido: null,

  // FLIGHTS
  airport: {
    iata: null,
    name: null,
    country: null,
    state: null,
    city: null,
    direccion: null,
  },
  oficinaRenta: {
    name: null,
    ptos_recogida: null,
    country: null,
    country_code: null,
    state: null,
    city: null,
    direccion: null,
  },
  onlyShow: false,

  // CARS
  telefonos: [],
  horarios: [],
  galeria: [],
  oficceRent: [],
  paiseCode: {
    name: null,
    countrySlug: null,
    code: null,
  },
  driverAgeCumple: false,

  // MARKUPS
  markupsList: [],

  // CONTRATE
  suplementosContrateCar: [],
  suplementoCar: {
    from: null,
    to: null,
    price: 0,
    suplemento_id: null,
    obligatorio: false,
  },
  categoryContrateCar: [],
  marcasModeloContrateCar: [],
  temporadasContrateCar: [],
  pricesTemporadasContrateCar: [],
  cargandoCuposTemporadasContrateCar: false,

  // FLIGHT
  suplementosContrateFlight: [],
  suplementoFlight: {
    from: null,
    to: null,
    price: 0,
    suplemento_id: null,
    obligatorio: false,
    online: false,
  },

  // HOTELS
  loadImg: false,
  galeriaHotels: [],
  videos: [],
  videos_url: [],
  addressHotel: {
    country: null,
    state: null,
    city: null,
    direccion: null,
  },
  contactsHotel: [],
  onlyAdult: false,
  allInclude: false,

  // CONTRATE
  observationsHotel: [],
  idsHotelsAsociados: [],
  temporadasContrateHotel: [],
  hotelsAsociadosTemporadas: [],
  idsHotelsAsociadosTemporadas: [],
  cantPaxsContrate: 3,
  pricesHotelContrate: [],
  seasonContrateHotel: {
    price_estancia_dbl: 'pppn',
    is_tarifa_price_estancia_dbl: true,
  },
  seasonsContrateHotel: [],
  roomsSeasonContrateHotel: [],
  maxOcupationRoomsSeasonContrateHotel: [],
  ocupationRoomsSeasonContrateHotel: [],
  cuposRoomsSeasonContrateHotel: [],
  releaseRoomsSeasonContrateHotel: [],
  tipoCamaRoomsSeasonContrateHotel: [],
  estaciaMinRoomsSeasonContrateHotel: [],
  estaciaMaxRoomsSeasonContrateHotel: [],
  pricesDBLRoomsSeasonContrateHotel: [],
  suplementsReduccTarifasSeasonContrateHotel: [],
  suplementsAlimenticiosSeasonContrateHotel: [],
  suplementsOtrosSeasonContrateHotel: [],
  politicasChildSeasonContrateHotel: [],
  notShowSeasonContrateHotel: [],
  suplementsSeasonContrateHotel: [],
  reduccionsSeasonContrateHotel: [],
  rangeAgeContrateHotel: {
    adults_min_years: 13,
    childs_max_years: 12.99,
    infant_max_years: 1.99,
  },
  ofertasSeasonContrateHotel: [],
  ofertasSpecialSeasonContrateHotel: [],

  // COTIZADOR
  cotizadorFlight: {
    cant_adults: 1,
    cant_ninnos: 0,
    cant_infant: 0,
    aerolinea: 0,
    aerolinea_name: 'Todas',
    onlyIda: false,
    destinosMultiples: false,
  },
  destinosCotizadorFlight: [
    {
      origen: null,
      destino: null,
      from: null,
      to: null,
    },
  ],
  searchingFlightCotizador: false,
  pageSearchingFlightCotizador: 1,
  resultCotizadorIdaFlight: [],
  aerolineCotizadorIdaFlight: [],
  searchCotizadorIdaFlight: false,
  searchCotizadorFlights: false,
  aerolineCotizadorRegresoFlight: [],
  aerolineCotizadorDestinosFlight: [],
  tarifaEconCotizadorIdaFlight: {},
  resultCotizadorBackFlight: [],
  filtrosCotizadorFlight: {
    aerolinea: 0,
    escala: 0,
    duracionEscala: 20,
    duracionViaje: 20,
    frecuencias: ['0', '1', '2', '3', '4', '5', '6'],
    airport: {},
    clase: 0,
    equipaje: 0,
    byMejorOpcion: true,
    byMasEconomico: false,
    byMasRapido: false,
  },
  cotizadorFlightSelect: null,
  cotizadorFlightSelectMarkups: {
    markup: 0,
    valueMarkup: null,
    descuento: 0,
    valueDescuento: null,
  },
  cotizadorPaxFlightSelect: [],
  cotizadorFlightFavorite: [],
  idFlightFavorite: [],
  scheduleResult: [],
  pageResult: 1,
  itemsPerPageResult: 10,
  searchResult: null,
  posCotizador: 0,
  pagination: {
    current: 1,
    total: 0,
  },

  // CARS
  cotizadorCars: {
    recogida: null,
    entrega: null,
    entregaDiffRecogida: false,
    dateRecogida: null,
    dateEntrega: null,
    transmision_id: null,
    rentadora_id: null,
  },
  declineTerms: true,
  searchingCar: false,
  cotizadorCarsResult: [],
  cotizadorCarsResultSelect: null,
  cotizadorCarsResultSelectEdit: null,
  cotizadorCarsResultSelectEditWith: null,
  cotizadorPaxCarSelect: {
    nameConductorPrincipal: null,
    apellidosConductorPrincipal: null,
    nameSecoundConductorPrincipal: null,
    birthdayConductorPrincipal: null,
    nationalityConductorPrincipal: null,
    sexConductorPrincipal: null,
    noDocumentConductorPrincipal: null,
    noDocumentConductorPrincipalScanner: null,
    conductorAditional: false,
    nameConductorAditional: null,
    apellidosConductorAditional: null,
    nameSecoundConductorAditional: null,
    birthdayConductorAditional: null,
    nationalityConductorAditional: null,
    sexConductorAditional: null,
    noDocumentConductorAditional: null,
    noDocumentConductorAditionalScanner: null,
  },
  sameParamters: true,
  noDocumentConductorPrincipalScanner: null,
  noDocumentConductorAditionalScanner: null,
  cotizadorCarsSelectMarkups: {
    markup: 0,
    valueMarkup: null,
    descuento: 0,
    valueDescuento: null,
  },
  avalibleFiltrosCotizadorCar: false,
  filtrosCotizadorCar: {
    marca: null,
    modelo: null,
    transmision: null,
    tipo_auto: null,

    // byMejorOpcion: true,
    // byMasEconomico: false,
    // byMasRapido: false,
  },
  indexTab: -1,
  showTab: false,
  hotelsChanges: [],

  // HOTELS
  isLoadingSearch: true,
  isLoadingPagination: true,
  cotizadorHotels: {
    destino: null,
    dateIn: null,
    dateOut: null,
    proveedor_id: null,
    categoria_id: [],
    tipo_destino_id: null,
    ocupation: [
      {
        adults: 2,
        childs: 0,
        edades: [],
        room_id: null,
        paxs: [],
        showAllPaxs: false,
        priceNeto: null,
        priceNetoCurrency: null,
        price: null,
        priceTotal: null,
        priceWithMarkups: null,
        priceWithDescuento: null,
        cantAdulEdad: 2,
        cantTeenEdad: 0,
        cantMnrEdad: 0,
        cantInfEdad: 0,
      },
    ],
  },
  cotizadorHotelsRomms: [],
  showOcupationRoomHotel: false,
  searchingHotel: false,
  showMapHotels: false,
  cotizadorHotelsAll: 0,
  cotizadorHotelsResult: [],
  cotizadorHotelsResultOrigin: [],
  cotizadorHotelsResultPaginate: [],
  cotizadorHotelsResultSelect: null,
  cotizadorHotelsResultFilters: {
    name: null,
    categories: [],
    price: [0, 100000],
    tipoDestinos: [],
    cadenaHotelera: [],
    marcaHotelera: [],
    tipoReserva: [],
    contratos: [],
    services: [],
    intereses: [],
    orderByRecomendado: null,
    orderByCategory: null,
    orderByPrice: 'asc',
  },
  cotizadorHotelsSelectMarkups: {
    markup: 0,
    valueMarkup: null,
    descuento: 0,
    valueDescuento: null,
  },

  // AFILIADOS
  contactsAfiliados: [],

  // RESERVAS
  comentsGestor: [],
  comentsClient: [],

  // STOP SALE
  watchEvent: false,
  stopSales: {
    id: null,
    products: [],
    proveedor_id: [],
    contrate_id: [],

    // CARS
    category_id: [],
    marca_modelo_id: [],

    // HOTELS
    hotel_id: [],
    room_id: [],

    // FLIGHTS
    aeroline_id: [],
    flight_id: [],

    temporada_id: [],
  },
  stopSalesList: [],
  datesStopSale: [],

  // OFERTAS
  ofertEBBList: [],
  ofertEBBDates: {},
  ofertEBBDatesOrigin: {},
  indexHotelEBB: -1,
  showHotelEBB: false,
  indexRoomEBB: -1,
  showRoomEBB: false,

  // CAR-SHOP
  carItems: [],
  clientCarShop: {
    name: null,
    apellidos: null,
    email: null,
    subject: null,
    message: null,
    telefono: null,
    is_client_corporate: false,
    id_client_corporate: null,
    id_cta_bancaria: null,
    emails: [],
  },
  indexCarItem: -1,
  showCarItem: false,
  loadingBtn: false,
  loadingImg: true,

  // CTAS
  checkAll: false,
  itemsCheck: [],
  checkAllCobros: false,
  itemsCheckCobros: [],
  checkAllPaysEmit: false,
  itemsCheckPaysEmit: [],
  checkAllPaysRec: false,
  itemsCheckPaysRec: [],

  // SEASTING
  seasting: {
    headersLeft: [],
    seatsLeft: [],
    headersRight: [],
    seatsRight: [],
    count: {
      columnsLeft: 3,
      columnsRight: 3,
      rows: 15,
    },
  },

  // EXPORT
  filtrosExport: {},

  // TARIFARIOS
  itemsTarifarios: [],
  searchCars: false,
  searchHotels: false,
  searchFlights: false,

  // PROPIETARIO
  loadingContent: false,
  dataBancaria: {
    tipo_fiscal: null,
    cif_nif_facturation: null,
    info_fiscal: {
      address: [
        {
          address: null,
        },
      ],
      code_postal: null,
      localidad: null,
      country: null,
    },
  },
  tarifariosOriginHotels: [],
  tarifariosHotels: [],
  tarifasHotels: [],
  tarifasDirectoHotels: [],
  tarifariosOrigin: null,
  tarifaSelectDirectoHotels: -1,
  loadingTarifarios: false,
  tarifarioFiltersHotels: {
    slug_category_tarifa: null,
    room_id: null,
    plan: null,
    adult: 2,
    teen: 0,
    children: 0,
    babies: 0,
    from: null,
    to: null,
  },

  itemsPerPageResultSearch: 10,
  paginationResult: {
    current: 1,
    total: 0,
  },

  // FILTROS
  itemsPerPage: 10,
  itemsPage: 1,
  itemsCont: 0,
  reservasAll: [],
  cotizationsAll: [],
  reservasPaginate: [{
    date_servicio: [],
    afiliado: {
      name: '',
      company: '',
      nivel: '',
    },
    state_reserva: '',
  }],
  refundAll: [],
  refundPaginate: [],
  cotizationsPaginate: [],
  idUserLevel0: [],
  idUserAfiliates: [],
  filtersReservas: {
    dateReserveFrom: null,
    dateReserveTo: null,
    dateServiceFrom: null,
    dateServiceTo: null,
    tipo_product: null,
    fullName: null,
    afiliate: null,
    seller: null,
    codeReserva: null,
    state: [],
  },
  filtersCotizations: {
    dateReserveFrom: null,
    dateReserveTo: null,
    dateServiceFrom: null,
    dateServiceTo: null,
    tipo_product: null,
    fullName: null,
    afiliate: null,
    seller: null,
    codeReserva: null,
    state: null,
  },
  productsAll: [],
  productsPaginate: [],
  filtersCars: {
    marca_id: null,
    modelo_id: null,
    transmision_id: null,
    tipo_auto_id: null,
    name: null,
    not_public: null,
    sin_marca: null,
    sin_modelo: null,
    sin_transmision: null,
    sin_tipo_auto: null,
    sin_combustible: null,
    sin_cant_puertas: null,
    sin_cant_paxs: null,
    sin_cant_bag_big: null,
    sin_cant_bag_small: null,
    sin_um: null,
    sin_capacidad_comb: null,
    sin_consumo_comb: null,
    sin_motor: null,
    sin_caracteristicas: null,
    sin_prioridad: null,
    sin_fotos: null,
    sin_tag_fotos: null,
    sin_tag_principal: null,
  },
  contNotPublic: 0,
  contInfoPendient: 0,

  // CHATBOT
  optionsChatBotMessages: [],
  chatActiveAdmin: null,

  tasaCambioContrate: 1,
  currencySymbolContrate: '$',
  currencyCodeContrate: 'USD',
  tasaCambioFacturar: 1,
  currencySymbolFacturar: '$',
  currencyCodeFacturar: 'USD',
  showMenuNavBarCurrency: false,
  showMenuNavBarMoreCurrency: false,
}

export default {
  state,
  mutations,
  actions,
}
