<template>
  <v-card
    style="cursor: pointer;"
    @click="goContrates()"
  >
    <v-card-text>
      <span
        style="font-size: 20px;"
      >
        <b>
          {{ $t('dashboard.contrateHotels') }}
        </b>
      </span>
      <br />
      <span style="font-size: 14px;">{{ $t('dashboard.contrateHotels1') }}</span>
    </v-card-text>

    <v-card-text>
      <v-row>
        <!--SIN TARIFARIOS-->
        <v-col
          cols="6"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="warning"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              mdi-currency-usd-off
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t('dashboard.sinTarifario') }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ contratesPendientsHotelsUser.cantSinTarifarios }}
            </h3>
          </div>
        </v-col>
        <!--SIN TARIFAS-->
        <v-col
          cols="6"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="warning"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              mdi-chart-timeline-variant
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t('dashboard.tarifasUndefined') }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ contratesPendientsHotelsUser.cantSinTarifas }}
            </h3>
          </div>
        </v-col>
        <!--VENCEN EN 3 MESES-->
        <v-col
          cols="6"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="info"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              mdi-currency-usd
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t('dashboard.venceIn', { n: 3 }) }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ contratesPendientsHotelsUser.cantVencen3 }}
            </h3>
          </div>
        </v-col>
        <!--VENCEN EN 6 MESES-->
        <v-col
          cols="6"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="primary"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              mdi-currency-usd
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t('dashboard.venceIn', { n: 6 }) }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ contratesPendientsHotelsUser.cantVencen6 }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
      contratesPendientsHotelsUser: {
        cantSinTarifarios: 6,
        cantSinTarifas: 4,
        cantVencen3: 7,
        cantVencen6: 15,
      },
    }
  },
  methods: {
    goContrates() {
      this.$router.push({ name: 'page-static-contrates-hotels' })
    },
  },
}
</script>
