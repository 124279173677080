<template>
  <v-col
    v-if="cardsDashboard.includes('download-apps')"
    cols="12"
    order="10"
    class="align-self-top"
  >
    <AppDownload
      v-for="(app, ind) in appsDownload"
      :key="ind"
      :app="app"
      :user="user"
    />
  </v-col>
</template>

<script>
import { mapState } from 'vuex'
import AppDownload from './apps/AppDownload.vue'

export default {
  components: {
    AppDownload,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      cardsDashboard: state => state.app.cardsDashboard,
      appsDownload: state => state.app.appsDownload,
    }),
  },
}
</script>
