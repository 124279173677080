<template>
  <v-row>
    <template v-if="showChart">
      <CintilloCreateUser />
      <Contrates v-if="showChart" />
      <Sales v-if="showChart" />
      <ChatBot />
      <Notificaciones />
      <CintilloCreateSeller />
      <CintilloCreateAgente />
      <CintilloCreateAdmin />
      <Markups v-if="showChart" />
      <DataGeneral />
      <DeepL v-if="showChart" />
      <Apps />
    </template>
  </v-row>
</template>

<script>
import Apps from './b2b/Apps.vue'
import ChatBot from './b2b/ChatBot.vue'
import CintilloCreateUser from './b2b/cintillos/CintilloCreateUser.vue'
import CintilloCreateSeller from './b2b/cintillos/CintilloCreateSeller.vue'
import CintilloCreateAgente from './b2b/cintillos/CintilloCreateAgente.vue'
import CintilloCreateAdmin from './b2b/cintillos/CintilloCreateAdmin.vue'
import Contrates from './b2b/Contrates.vue'
import Sales from './b2b/Sales.vue'
import Notificaciones from './b2b/Notificaciones.vue'
import Markups from './b2b/Markups.vue'
import DeepL from './b2b/deepl/DeepL.vue'
import DataGeneral from './b2b/DataGeneral.vue'

export default {
  name: 'Dashboard',
  components: {
    Apps,
    ChatBot,
    CintilloCreateUser,
    CintilloCreateSeller,
    CintilloCreateAgente,
    CintilloCreateAdmin,
    Contrates,
    Sales,
    Notificaciones,
    Markups,
    DeepL,
    DataGeneral,
  },
  data() {
    return {
      showChart: false,
    }
  },
  created() {
    setTimeout(() => {
      this.showChart = true
    }, 1000)
  },
}
</script>
