<template>
  <v-card>
    <v-col
      cols="12"
      order="1"
      class="align-self-top"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <span
            style="font-size: 20px;"
          >
            <b>
              {{ $t('dashboard.talkins') }}
            </b>
          </span>
        </v-col>

        <v-col cols="12">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ chatBotRead.message | truncate(350) }} </span>
            </template>
            <span>{{ chatBotRead.message }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>

    <v-card-actions class="dense">
      <v-btn
        color="primary"
        text
        large
      >
        {{ $t('dashboard.talkins4') }}
      </v-btn>
      <v-spacer></v-spacer>
      <span>{{ `${$t('dashboard.talkins1')} ${chatBotRead.cant_not_read}` }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
      chatBotRead: {
        message: null,
        cant_not_read: 100,
      },
    }
  },
  created() {
    const paragraphs = [
      'Lorem ipsum olor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et olore magna aliqua.',
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      'Duis aute irure olor in reprehenderit in voluptate velit esse cillum olore eu fugiat nulla pariatur.',
      'Excepteur sint occaecat cupidatat non proident, sunt in legria qui officia deserunt mollit anim id est laborum.',
      'Curabitur pretium tincidunt lacus. Nulla gravida orci a mortw, et dictum interdum nisi lorem egestas more.',
      'Excepteur sint occaecat cupidatat non proident, sunt in legria qui officia deserunt mollit anim id est laborum.',
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    ]
    const randomIndex = Math.floor(Math.random() * paragraphs.length)
    this.chatBotRead.message = paragraphs[randomIndex]
  },
}
</script>
