<template>
  <v-col
    cols="12"
    order="1"
    class="align-self-top"
  >
    <DNS />
  </v-col>
</template>

<script>
import DNS from './notificaciones/DNS.vue'

export default {
  components: {
    DNS,
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
}
</script>
