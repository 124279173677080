<template>
  <v-col
    cols="8"
    order="1"
    class="align-self-top"
  >
    <DataGeneral />
  </v-col>
</template>

<script>
import DataGeneral from './dataGeneral/DataGeneral.vue'

export default {
  components: {
    DataGeneral,
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
}
</script>
