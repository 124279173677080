<template>
  <v-col cols="12">
    <v-row>
      <v-col
        cols="6"
      >
        <VentasMes />
      </v-col>
      <v-col
        cols="6"
      >
        <VentasMesByContrateHotel />
      </v-col>
      <v-col
        cols="6"
      >
        <VentasMesByContrateCar />
      </v-col>
      <v-col
        cols="6"
      >
        <template>
          <VendedorDestacado />
        </template>
      </v-col>
      <v-col
        cols="12"
      >
        <template>
          <VendedorVentasSemanal />
        </template>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import VentasMes from './sales/VentasMes.vue'
import VentasMesByContrateHotel from './sales/VentasMesByContrateHotel.vue'
import VentasMesByContrateCar from './sales/VentasMesByContrateCar.vue'
import VendedorDestacado from './sales/VendedorDestacado.vue'
import VendedorVentasSemanal from './sales/VendedorVentasSemanal.vue'

export default {
  components: {
    VentasMes,
    VentasMesByContrateHotel,
    VentasMesByContrateCar,
    VendedorDestacado,
    VendedorVentasSemanal,
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      isLoadingSales: false,
      sales: {},
      salesByContrate: [],
      salesMonth: {},
    }
  },
  created() {
    this.salesMonth = {
      amountAll: 1524.56,
    }
  },
}
</script>
