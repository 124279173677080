<template>
  <v-col
    cols="6"
    order="1"
    class="align-self-top"
  >
    <VencimientoHotelsAll />
  </v-col>
</template>

<script>
import VencimientoHotelsAll from './contrates/VencimientoHotelsAll.vue'

export default {
  components: {
    VencimientoHotelsAll,
  },
}
</script>
