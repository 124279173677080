// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'
// eslint-disable-next-line import/no-unresolved
import NotFound from '@/views/static/notfound.vue'
// eslint-disable-next-line import/no-unresolved
import dashboard from '@/views/static/dashboard/dashboard.vue'

const dashboardRoutes = [
  {
    path: '/dashboard/:perfil',
    name: 'dashboard',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/dashboards/General.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
    },
  },

  {
    path: '/page-static/:slug',
    name: 'page-static',
    // eslint-disable-next-line import/no-unresolved
    component: {
      render(h) {
        // eslint-disable-next-line no-unused-vars
        const page = this.$route.params.slug
        if (page === 'dashboard') {
          // const comp = `@/views/static/${page}/${page}.vue`

          return h(dashboard)
        }

        return h(NotFound)
      },
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/page-statics/contrates-hotels',
    name: 'page-static-contrates-hotels',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/static/dashboard/b2b/ContratesShow.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default dashboardRoutes
