<template>
  <v-col
    cols="4"
    order="1"
    class="align-self-top"
  >
    <Markups />
  </v-col>
</template>

<script>
import Markups from './markups/Markups.vue'

export default {
  components: {
    Markups,
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
}
</script>
