<!-- eslint-disable vue/no-v-html -->
<template>
  <v-col
    cols="12"
    order="1"
    class="align-self-top"
  >
    <v-card
      style="cursor: pointer;"
    >
      <v-row class="mx-2 my-3">
        <v-col
          cols="2"
        >
          <div>
            <v-img
              width="150"
              :src="img"
              class="gamification-john-pose-2"
            ></v-img>
          </div>
        </v-col>

        <v-spacer></v-spacer>
        <v-col
          cols="8"
        >
          <span
            style="font-size: 20px;"
          >
            <b>
              {{ $t('dashboard.createSeller') }}
            </b>
          </span>
          <br />
          <span style="font-size: 14px;">
            {{ $t('dashboard.createSeller1') }}
          </span>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/img/createSeller/createSeller1.svg'),
      imgs: [],
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
  created() {
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/createSeller/createSeller1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/createSeller/createSeller2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/createSeller/createSeller3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/createSeller/createSeller4.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/createSeller/createSeller5.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/createSeller/createSeller6.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/createSeller/createSeller7.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/createSeller/createSeller8.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/createSeller/createSeller9.svg'))

    this.shuffleDeck()
  },
  methods: {
    shuffleDeck() {
      const numbers = [...this.imgs]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgs = numbers
      // eslint-disable-next-line prefer-destructuring
      this.img = this.imgs[0]
    },
  },
}
</script>
<style lang="scss" scoped>
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  left: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  left: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  left: 30%;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    left: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }
    .gamification-tree {
      right: initial;
      left: 0;
    }
    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}
</style>
