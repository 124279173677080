// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  {
    path: '/translate/idioma',
    name: 'translate-idioma',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/Idioma.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_idioma',
    },
  },
  {
    path: '/translate/estructura',
    name: 'translate-estructura',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/Estructura.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_pages',
    },
  },
  {
    path: '/traslate/web',
    name: 'pages-web',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/Index.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_pages',
    },
  },
  {
    path: '/traslate/web-update',
    name: 'pages-web-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/form/Edit.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_pages',
    },
  },
  {
    path: '/traslate/catalogs-events',
    name: 'translate-catalogs-events',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/CatalogsEvents.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_events',
    },
  },
  {
    path: '/traslate/catalogs-update-title',
    name: 'translate-catalogs-update-title',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/utils/CatalogEditTitle.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_events',
    },
  },
  {
    path: '/traslate/catalogs-update-description',
    name: 'translate-catalogs-update-description',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/utils/CatalogEditDescription.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_events',
    },
  },
  {
    path: '/traslate/events-update-title',
    name: 'translate-events-update-title',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/utils/EventEditTitle.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_events',
    },
  },
  {
    path: '/traslate/events-update-description',
    name: 'translate-events-update-description',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/utils/EventEditDescription.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_events',
    },
  },

  {
    path: '/traslate/perfils',
    name: 'translate-perfils',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/PerfilAfiliado.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_perfil_afiliados',
    },
  },
  {
    path: '/traslate/perfils-name',
    name: 'translate-perfils-name',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/utils/PerfilAfiliadoEditName.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_perfil_afiliados',
    },
  },
  {
    path: '/traslate/perfils-description',
    name: 'translate-perfils-description',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/utils/PerfilAfiliadoEditDescription.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_perfil_afiliados',
    },
  },

  {
    path: '/traslate/hotel-product',
    name: 'traslate-hotel-product',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/HotelsProduct.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_hotel',
    },
  },
]

export default formTable
