import { render, staticRenderFns } from "./AppBarI18n.vue?vue&type=template&id=2d3e6f9a&scoped=true&"
import script from "./AppBarI18n.vue?vue&type=script&lang=js&"
export * from "./AppBarI18n.vue?vue&type=script&lang=js&"
import style0 from "./AppBarI18n.vue?vue&type=style&index=0&id=2d3e6f9a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d3e6f9a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VList,VListItem,VListItemGroup,VListItemTitle,VMenu,VRow,VSpacer})
