<template>
  <v-row>
    <template>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <h1 style="color: red">
            Component Not Found
          </h1>
        </v-col>
      </v-row>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>
