import creations from './identificador/creations'
import chatbot from './chatbot/creations'
import notifications from './notifications/creations'
import apiKeys from './api_keys/creations'
import apps from './apps/creations'
import agentes from './agentes/creations'
import access from './access'
import catalogs from './catalogs/creations'
import events from './events/creations'

export default [
  ...creations,
  ...chatbot,
  ...notifications,
  ...apiKeys,
  ...apps,
  ...agentes,
  ...access,
  ...catalogs,
  ...events,
]
