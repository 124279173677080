<!-- eslint-disable vue/no-v-html -->
<template>
  <v-col
    cols="12"
    order="2"
    class="align-self-top"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <DeepLOff />
      </v-col>
      <v-col
        cols="5"
      >
        <DeepLCount />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'
import DeepLOff from './DeepLOff.vue'
import DeepLCount from './DeepLCount.vue'

export default {
  components: {
    DeepLOff,
    DeepLCount,
  },
  data() {
    return {
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/img/translate/translate1.svg'),
      imgs: [],
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
}
</script>
