/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line camelcase, import/no-unresolved
// import messages_es from '@/plugins/i18n/locales/es'
// eslint-disable-next-line camelcase, import/no-unresolved
// import messages_en from '@/plugins/i18n/locales/en'
// eslint-disable-next-line import/no-unresolved, camelcase
// import messages_pt from '@/plugins/i18n/locales/pt'
// eslint-disable-next-line import/no-unresolved, camelcase
// import messages_de from '@/plugins/i18n/locales/de'
// eslint-disable-next-line import/no-unresolved, camelcase
// import messages_ru from '@/plugins/i18n/locales/ru'
// eslint-disable-next-line import/no-unresolved, camelcase
// import messages_fr from '@/plugins/i18n/locales/fr'
// eslint-disable-next-line import/no-unresolved, camelcase
// import messages_ch from '@/plugins/i18n/locales/ch'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
// eslint-disable-next-line import/no-unresolved
import store from '@/store'

Vue.use(VueI18n)

// DETECTAR IDIOMA DEL NAVEGADOR
let language = navigator.language || navigator.userLanguage
if (localStorage.getItem('lenguaje') !== null) {
  language = localStorage.getItem('lenguaje')
}

export const i18n = new VueI18n({
  locale: language
    ? language.includes('es')
      ? 'es'
      : language.includes('en')
        ? 'en'
        : language.includes('pt')
          ? 'pt'
          : language.includes('de')
            ? 'de'
            : language.includes('ru')
              ? 'ru'
              : language.includes('fr')
                ? 'fr'
                : language.includes('ch')
                  ? 'ch'
                  : 'es'
    : 'es', // set locale
  fallbackLocale: 'en',
  messages: {
    /*
    es: messages_es,
    en: messages_en,
    pt: messages_pt,
    de: messages_de,
    ru: messages_ru,
    fr: messages_fr,
    ch: messages_ch,
    */
  }, // set locale messages
})

const loadTranslations = async () => {
  try {
    const response = await axios
      .post(`${process.env.VUE_APP_API_URL}traslate/i18n`)

    const translations = response.data

    i18n.setLocaleMessage('es', translations.es)
    i18n.setLocaleMessage('pt', translations.pt)
    i18n.setLocaleMessage('en', translations.en)
    i18n.setLocaleMessage('de', translations.de)
    i18n.setLocaleMessage('ru', translations.ru)
    i18n.setLocaleMessage('fr', translations.fr)
  } catch (error) {
    console.error('Error al cargar las traducciones:', error)
  }
}

// VERIFICAR LOS IDIOMAS ACTIVOS
const loadLanguages = async () => {
  try {
    const response = await axios
      .post(`${process.env.VUE_APP_API_URL}languages/actives`)

    const languagesRes = response.data.data.data
    store.commit('setLanguages', languagesRes)
  } catch (error) {
    console.error('Error al cargar las traducciones:', error)
  }
}

loadTranslations()
loadLanguages()

const loadedLanguages = ['es'] // nuestro idioma predeterminado que está precargado

function setI18nLanguage(lang) {
  i18n.locale = lang

  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  /* eslint-disable prefer-template */
  return import(/* webpackChunkName: "lang-[request]" */ '@/plugins/i18n/locales/' + lang + '.js').then(msgs => {
    i18n.setLocaleMessage(lang, msgs.default)
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  })
  /* eslint-enable */
}
