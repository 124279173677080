<template>
  <v-menu
    v-if="languages.length > 0"
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="d-flex align-center"
        v-on="on"
      >
        <!--<v-img
          :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
          :alt="$i18n.locale"
          height="14px"
          width="22px"
          class="me-2"
        ></v-img>-->
        <country-flag
          :country="$i18n.locale === 'en' ? 'gb' : $i18n.locale"
          height="14px"
          width="22px"
          class="mr-1"
          style="margin-top: 0 !important;padding-top: 0 !important;"
        />
        <span v-if="$vuetify.breakpoint.smAndUp">{{ languages.find(l => l.locale === $i18n.locale).name }}</span>
      </div>
    </template>

    <!-- Options List @change="updateActiveLocale" -->
    <v-list>
      <v-list-item-group
        :value="$i18n.locale"
      >
        <v-list-item
          v-for="locale in availableLocales"
          :key="locale.locale"
          :value="locale.locale"
          @click="setLocale(locale.locale)"
        >
          <!--<v-img
            :src="locale.img"
            height="14px"
            width="22px"
            :alt="locale.locale"
            class="me-2"
          ></v-img>-->
          <v-list-item-title>
            <v-row>
              <v-col cols="2">
                <country-flag
                  :country="locale.locale === 'en' ? 'gb' : locale.locale"
                  size="normal"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="8">
                <p class="mt-2">
                  {{ locale.name }}
                </p>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
// import { loadLanguageAsync } from '@/plugins/i18n'
// eslint-disable-next-line import/no-unresolved
// import useAppConfig from '@core/@app-config/useAppConfig'
import { mapState, mapMutations } from 'vuex'

export default {
  /* setup() {
    // const { isRtl } = useAppConfig()

    const locales = [
      {
        title: 'Español',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/es.png'),
        locale: 'es',
      },
      {
        title: 'Português',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/pt.png'),
        locale: 'pt',
      },
      {
        title: 'English',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },
      {
        title: 'Deutsch',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/de.png'),
        locale: 'de',
      },
      {
        title: 'Русский',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/ru.png'),
        locale: 'ru',
      },
      {
        title: 'Français',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/fr.png'),
        locale: 'fr',
      },

      {
        title: '中國人',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/ch.png'),
        locale: 'ch',
      },
    ]

    const updateActiveLocale = locale => {
      // Set to RTL mode if locale is arabic
      isRtl.value = locale === 'ar'

      loadLanguageAsync(locale)
    }

    return {
      locales,

      // updateActiveLocale,
    }
  }, */
  data() {
    return {
      locales: [],
      formLocales: [],
    }
  },
  computed: {
    ...mapState({
      languages: state => state.app.languages,
    }),
    availableLocales() {
      return this.languages.filter(i => i.locale !== this.$i18n.locale)
    },
  },
  created() {
    this.fetchDataFromEndpoints()

    /* this.locales.push({
      title: 'Español',
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/images/flags/es.png'),
      locale: 'es',
    })
    this.locales.push({
      title: 'Português',
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/images/flags/pt.png'),
      locale: 'pt',
    })
    this.locales.push({
      title: 'English',
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/images/flags/en.png'),
      locale: 'en',
    })
    this.locales.push({
      title: 'Deutsch',
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/images/flags/de.png'),
      locale: 'de',
    })
    this.locales.push({
      title: 'Русский',
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/images/flags/ru.png'),
      locale: 'ru',
    })
    this.locales.push({
      title: 'Français',
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/images/flags/fr.png'),
      locale: 'fr',
    }) */
  },
  methods: {
    ...mapMutations(['setLanguages']),
    async fetchDataFromEndpoints() {
      try {
        const [
          resItems,
        ] = await Promise.all([
          this.axios
            .post('languages/actives', {}, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resItems.data.success === false) { } else {
          this.setLanguages(resItems.data.data.data)
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    setLocale(locale) {
      this.$i18n.locale = locale

      localStorage.setItem('lenguaje', locale)

      const json = {
        locale,
      }
      this.axios
        .post('profile/update-data', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) { }
        })
    },
  },
}
</script>
<style scoped>
.normal-flag {
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}
</style>
