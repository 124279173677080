import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameProject: process.env.VUE_APP_PROJ_NAME,
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.$echo.channel('chatnotread').listen('.chatnotread', data => {
      this.setChatBotRead(data.data)
    })
  },
  methods: {
    ...mapMutations([
      'setConfigDataGeneral',
      'setCardsDashboard',
      'setChatBotRead',
      'setSalesMonths',
      'setSalesWeeks',
      'setSalesMonthPreviews',
      'setContratesPendientsHotelsAll',
      'setAppsDownload',
      'setDnsEmpty',
      'setProductsList',
      'setConfigCotizadorHotel',
      'setMaxAgeCotizadorHotel',
      'setListUser',
      'setShowCardMarkups',
      'setShowCardIdentificadorInfo',
      'setShowCardIdentificadorBanco',
      'setShowCardIdentificadorBooking',
      'setShowCardIdentificadorIden',
      'setDeepLCountTranslate',
      'setActiveDeepL',
      'setAfiliadoPendient',
    ]),
    async fetchDataFromEndpoints() {
      try {
        const [
          res,
          resSales,
          resSalesMonthPreview,
          resSalesWeekPreview,
          resContratesAll,
          resCardsDashboard,
          resApps,
          resDns,
          resProducts,
          resConfCotHotel,
          resMaxAgeCotHotel,
          resUsers,
          resMarkups,
          resDataGeneral,
          resDeepL,
          resComanies,
        ] = await Promise.all([
          this.axios.get('configuration-view/data-general'),
          this.axios.post('sales/sales-month'),
          this.axios.post('sales/sales-month-users'),
          this.axios.post('sales/sales-weeks-users'),
          this.axios.post('contrate_hotels/pendients'),
          this.axios.get('dashboard_cards'),
          this.axios.get('apps/list-download'),
          this.axios.get('dns/list'),
          this.axios.post('type_product/list'),

          // COTIZADOR
          this.axios.get('config-search/hotels'),
          this.axios.get('config-search-max-age-hotels'),

          // USERS
          this.axios.post('users/list'),

          // MARKUPS
          this.axios.post('markups/exist'),

          // DATA GENERAL
          this.axios.post('data-general/list'),

          // DEEPL
          this.axios.post('deepl/list'),

          // COMPANIES
          this.axios.post('companies/pendients'),
        ])

        // CONFIG
        if (res.data.success) {
          const jsonConfig = {
            color: res.data.data.data.data.color,
            favicon: res.data.data.data.data.favicon,
            isotipo: res.data.data.data.data.isotipo,
            logo: res.data.data.data.data.logo,
            name: res.data.data.data.data.name,
          }
          if (res.data.data.data.data.favicon) {
            const favicon = document.getElementById('favicon')
            favicon.href = `${this.rutaPublic}${res.data.data.data.data.favicon}`
          }

          if (res.data.data.data.data.name) {
            const ppp = 'Panel de Administración / '
            document.title = this.nameProject === 'wwwww'
              ? `${ppp}${res.data.data.data.data.name}, plataforma de viajes`
              : `${ppp}${res.data.data.data.data.name}`
          }
          this.setConfigDataGeneral(jsonConfig)
          this.$vuetify.theme.themes.light.primary = res.data.data.data.data.color
          localStorage.setItem('configData', JSON.stringify(jsonConfig))
        }

        // SALES
        // eslint-disable-next-line no-empty
        if (resSales.data.success === false) { } else {
          localStorage.setItem('salesMonth', JSON.stringify(resSales.data))
          this.setSalesMonths(resSales.data)
        }

        // SALES MONTH PREVIEW
        // eslint-disable-next-line no-empty
        if (resSales.data.success === false) { } else {
          localStorage.setItem('salesMonthPreview', JSON.stringify(resSalesMonthPreview.data))
          this.setSalesMonthPreviews(resSalesMonthPreview.data)
        }

        // SALES WEEK
        // eslint-disable-next-line no-empty
        if (resSales.data.success === false) { } else {
          localStorage.setItem('salesWeekPreview', JSON.stringify(resSalesWeekPreview.data))
          this.setSalesWeeks(resSalesWeekPreview.data)
        }

        // CONTRATES
        localStorage.setItem('contratesHotelsAll', JSON.stringify(resContratesAll.data))
        this.setContratesPendientsHotelsAll(resContratesAll.data)

        // CARDS DEL DASHBOARD
        localStorage.setItem('modulesCheck', JSON.stringify(resCardsDashboard.data.data.notifications))
        this.setCardsDashboard(resCardsDashboard.data.data.notifications)

        // CHATBOT
        // console.log(resChatBot.data)

        this.setAppsDownload(resApps.data)
        this.setDnsEmpty(resDns.data.length === 0)

        // LISTA DE PRODUCTOS PARA EL COTIZADOR
        this.setProductsList(resProducts.data.data)

        // CONFIG COTIZADOR HOTEL
        if (resConfCotHotel.data.success) {
          this.setConfigCotizadorHotel(resConfCotHotel.data.data.data.parameters)
        }
        if (resMaxAgeCotHotel.data.success) {
          this.setMaxAgeCotizadorHotel(resMaxAgeCotHotel.data.data.age)
        }

        // USERS
        this.setListUser(resUsers.data)

        // MARKUPS
        this.setShowCardMarkups(resMarkups.data.existen)

        this.setShowCardIdentificadorInfo(resDataGeneral.data.showCardIdentificadorInfo)
        this.setShowCardIdentificadorBanco(resDataGeneral.data.showCardIdentificadorBanco)
        this.setShowCardIdentificadorBooking(resDataGeneral.data.showCardIdentificadorBooking)
        this.setShowCardIdentificadorIden(resDataGeneral.data.showCardIdentificadorIden)

        this.setActiveDeepL(resDeepL.data.active)
        this.setDeepLCountTranslate({
          countAll: resDeepL.data.countAll,
          countPt: resDeepL.data.countPt,
          countEn: resDeepL.data.countEn,
          countDe: resDeepL.data.countDe,
          countRu: resDeepL.data.countRu,
          countFr: resDeepL.data.countFr,
        })

        this.setAfiliadoPendient(resComanies.data)
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
  },
  created() {
    this.fetchDataFromEndpoints()
  },
}
