<!-- eslint-disable vue/no-v-html -->
<template>
  <v-row
    class="mx-2"
  >
    <v-col
      cols="12"
      md="6"
      lg="4"
      class="align-self-start"
      style="cursor: pointer;"
    >
      <v-card color="text-center">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mt-10"
            icon
            size="50"
          >
            <v-icon
              size="2rem"
              color="primary"
            >
              mdi-at
            </v-icon>
          </v-avatar>
          <h6 class="text-xl mt-4 font-weight-medium">
            {{ $t('dashboard.support') }}
          </h6>
        </v-card-text>
        <v-card-text>
          {{ $t('dashboard.support1') }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }
    .gamification-tree {
      right: initial;
      left: 0;
    }
    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}
</style>
